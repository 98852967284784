export const userTokenStorageKey = 'idv_ut';

export function sessionStorageClearValue(key: string) {
  sessionStorage.removeItem(key);
}

export function sessionStorageSetValue(key: string, value: string) {
  sessionStorage.setItem(key, value);
}

export function sessionStorageGetValue(key: string) {
  return sessionStorage.getItem(key);
}
