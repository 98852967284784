import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ConsentModal from '../../components/consent-modal/consent-modal.component';
import TenantLogoHeader from '../../components/logo-header/logo-header.component';
import PageComponent from '../../components/page/page.component';
import { mobileDetection } from '../../hooks/device-detection';
import { ErrorType, useErrorNavigation } from '../../hooks/error-navigation';
import { useLogger } from '../../hooks/use-logger';
import { FlowActions, useFlowContext } from '../../hooks/verification-flow';
import { routes } from '../../routes/routes.utils';
import { ConsentTypes } from '../../types/settings-types';
import { httpPost } from '../../utils/http';
import { eventFactory } from '../../utils/monitoring/event-factory';
import { hitConsent, startedSession } from '../../utils/monitoring/init-ui-monitoring';
import { sendEvent } from '../../utils/monitoring/send-bi';
import { captureException } from '../../utils/monitoring/sentry';

import './onboarding-instructions.page.scss';

const LazyOnePageOnboarding = React.lazy(
  () => import('../../components/onboarding-instruction/one-page-onboarding.component'),
);

interface VerificationStartResponse {
  session_id: string;
  required_images: string[];
}

const OnboardingInstructions: React.FC = () => {
  const { handleError, handleTimeout } = useErrorNavigation();
  const { logMessage } = useLogger('');
  const { state, dispatch } = useFlowContext();
  const [sessionId, setSessionId] = useState<string>();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const isStartedOnDesktop = params.get('startedOnDesktop') ? true : false;
  const showConsentMessage = state.sdkSettings?.consent?.isEnable;
  const [openConsentModal, setOpenConsentModal] = useState(false);
  const { startToken = '' } = useParams();
  const { isMobile } = mobileDetection();
  const isCheckboxConsent = state.sdkSettings?.consent?.type === ConsentTypes.nonUS;
  const isUsConsent = state.sdkSettings?.consent?.type === ConsentTypes.usOnly;
  const isCustomConsent = state.sdkSettings?.consent?.type === ConsentTypes.customConsent;
  const customConsentText = state.sdkSettings?.consent?.message;
  const customAdditionalConsentText = state.sdkSettings?.consent?.custom_message;
  useEffect(() => {
    logMessage('Showing instructions');
  }, []);
  const navigateToScanPage = (sid: string) => {
    const currentStep = state.missingImages?.[0];
    let step: FlowActions;
    let path;
    if (currentStep === 'document_front') {
      step = 'DOCUMENT_FRONT';
      path = routes.scanDocumentFront.path;
    } else if (currentStep === 'document_back') {
      step = 'DOCUMENT_BACK';
      path = routes.scanDocumentBack.path;
    } else if (currentStep === 'selfie') {
      step = 'SELFIE';
      path = routes.selfieImage.path;
    } else {
      step = 'ERROR';
      path = routes.error.path;
    }
    dispatch({
      ...state,
      sessionId: sid,
      currentStep: step,
      isStartedOnDesktop: isStartedOnDesktop,
    });
    navigate(`/session/${sid}/${path}`);
  };

  const handleAccept = (responseSid?: string) => {
    logMessage('Consent accepted');
    if (state.sdkSettings?.sdk_enabled) {
      navigate(`/${startToken}/${routes.sdkFlow.path}`);
      return;
    }
    const sid = sessionId ?? responseSid;
    if (!sid) {
      throw new Error('Session id is not defined');
    }
    let attempt = 1;

    const saveConsent = () => {
      httpPost(`/verify/api/v1/verification/${sid}/consent`, {})
        .then(() => {
          hitConsent('true');
          if (!isCheckboxConsent) {
            setOpenConsentModal(false);
            navigateToScanPage(sid);
          }
        })
        .catch((error) => {
          //we want to retry up to 3 calls before we go to error page
          if (attempt <= 3) {
            attempt++;
            saveConsent();
          } else {
            hitConsent('false');
            const additionalInfo = { sessionId: sid };
            captureException(new Error('Failed to submit consent'), additionalInfo);
            handleError(error as Error, state.currentStep, sid);
          }
        });
    };
    saveConsent();
  };

  const hostedStart = async () => {
    let attempt = 1;

    const startSession = () => {
      httpPost<VerificationStartResponse>('/verify/api/v1/verification/start', {
        start_token: startToken,
        accessed_from_desktop: !isMobile,
        client_type: 'hosted_app',
        consent: undefined,
      })
        .then(async (res) => {
          logMessage('Session started');
          const sid = res.data.session_id;
          setSessionId(sid);
          dispatch({
            ...state,
            sessionId: sid,
          });
          startedSession('true');
          if (showConsentMessage && !isCheckboxConsent) {
            openConsent();
            return;
          }
          if (isCheckboxConsent) {
            dispatch({
              ...state,
              previousStep: 'ON_BOARDING',
              currentStep: 'CONSENT',
            });
            const eventReport = eventFactory.createPageViewEvent('consent');
            await sendEvent(sid, eventReport);
            handleAccept(res.data.session_id);
          }
          navigateToScanPage(res.data.session_id);
        })
        .catch((error) => {
          if (error?.message.includes('Forbidden') || error?.message.includes('403')) {
            captureException(error as Error, { start_token: startToken, ...error });
            handleTimeout(error as Error, state.currentStep, ErrorType.sessionTimeout, state.sessionId);
          } else {
            //we want to retry up to 3 calls before we go to error page
            if (attempt <= 3) {
              attempt++;
              startSession();
            } else {
              startedSession('false');
              captureException(new Error('Failed to start session'), { start_token: startToken, ...error });
              handleError(new Error('Failed to start session'), state.currentStep, state.sessionId);
            }
          }
        });
    };

    startSession();
  };

  const handleExit = async () => {
    if (state.sessionId) {
      navigateToScanPage(state.sessionId);
    } else {
      hostedStart();
    }
  };

  const openConsent = () => {
    logMessage('Showing consent');
    dispatch({
      ...state,
      previousStep: 'ON_BOARDING',
      currentStep: 'CONSENT',
    });
    setOpenConsentModal(true);
  };

  return (
    <>
      <PageComponent className={'onboarding-instructions'} title={<TenantLogoHeader />}>
        <>
          <LazyOnePageOnboarding handleExit={handleExit} isCheckboxConsent={isCheckboxConsent} />
          <ConsentModal
            className={`${openConsentModal ? 'show' : ''}`}
            handleAccept={handleAccept}
            clickedExit={() => {
              logMessage('Consent declined');
              setOpenConsentModal(false);
            }}
            isUsConsent={isUsConsent}
            isCustomConsent={isCustomConsent}
            customConsentText={customConsentText}
            customAdditionalConsentText={customAdditionalConsentText}
          ></ConsentModal>
          <div
            className={`consent-modal-overlay ${openConsentModal ? 'show' : ''}`}
            onClick={() => setOpenConsentModal(!openConsentModal)}
          />
        </>
      </PageComponent>
    </>
  );
};

export default OnboardingInstructions;
