const isDebuggerFlagInUrl = () => window.location?.search?.includes('debugger');

const isDebuggerFlagInConfig = () => window?.uiConfig?.sdkSettings?.is_debugger_enabled;

const isDebuggerFlagInSessionStorage = () => sessionStorage.getItem('debugger') === 'true';

export function isDebuggerEnabled() {
  const isDebugger = [isDebuggerFlagInUrl(), isDebuggerFlagInConfig(), isDebuggerFlagInSessionStorage()];
  return isDebugger.includes(true);
}

export function setDebuggerEnabled() {
  sessionStorage.setItem('debugger', 'true');
}
