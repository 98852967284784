import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Image from './components/image/image.component';
import IdvSdkComponent from './components/sdk/idv-sdk.component';
import Text from './components/text/text.component';
import { mobileDetection } from './hooks/device-detection';
import { useErrorNavigation } from './hooks/error-navigation';
import { useFlowContext } from './hooks/verification-flow';
import DisplayProvider from './pages/modular-pages/display-provider.component';
import GeneralIdvSdkComponent from './pages/modular-pages/general-idv-sdk/general-idv-sdk.component';
import RootRoutes from './routes/main.routes';
import { pathPrefix } from './utils/base-url-path';
import { captureException } from './utils/monitoring/sentry';
import { getParamFromUrl } from './utils/url-format/get-param-from-url';

import './app.scss';

const App: React.FC = function () {
  const { t } = useTranslation();
  const { state, moveToStepByEvent, moveToStepByError } = useFlowContext();
  const [startToken, setStartToken] = React.useState<string>();
  const { handleError } = useErrorNavigation();
  const { isMobile } = mobileDetection();

  useEffect(() => {
    if (state.sdkSettings.is_using_modular_flow) {
      if (Object.values(state.sdkSettings.flow_steps).filter((item) => item.isActive).length !== 1) {
        moveToStepByError('onError_general');
        throw new Error('There should be exactly one flow step active, marked as "start"');
      }

      moveToStepByEvent(isMobile ? 'onMobile' : 'onNonMobile');
    }

    const uiConfig = window.uiConfig;
    const token = getParamFromUrl(pathPrefix + ':startToken');
    setStartToken(token);
    if (!uiConfig) {
      const additionalInfo = { startToken: startToken };
      captureException(new Error('UI Config not found'), additionalInfo);
      handleError(new Error('UI Config not found'), 'onLoad');
    }
  }, []);

  return (
    <>
      {state.sdkSettings.is_using_modular_flow ? (
        <>
          <DisplayProvider />
          {state.sdkSettings?.sdk_enabled && startToken ? <GeneralIdvSdkComponent startToken={startToken} /> : null}
        </>
      ) : (
        <>
          <RootRoutes />
          {state.sdkSettings?.sdk_enabled && startToken ? <IdvSdkComponent startToken={startToken} /> : null}
        </>
      )}
      <div className={'landscape-overlay'}>
        <Image src={'/verify/app/assets/rotate-device.svg'} /> <br />
        <Text testId="rotate_your_screen">{t('rotate_your_screen')}</Text> <br />
        <Text strong testId="view_in_portrait">
          {t('view_in_portrait')}
        </Text>
      </div>
    </>
  );
};

export default App;
