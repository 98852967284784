const CompleteCheckSvg = (): React.ReactElement => {
  return (
    <svg
      className="complete-check-icon"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_583_19242)">
        <path
          d="M42.2268 21.3037C43.3694 22.4463 43.3694 24.3122 42.2268 25.4548L28.8091 38.8725C27.6665 40.0151 25.8007 40.0151 24.6581 38.8725L17.9492 32.1636C16.8066 31.021 16.8066 29.1551 17.9492 28.0126C19.0918 26.87 20.9577 26.87 22.1003 28.0126L26.7336 32.6458L38.0757 21.3037C39.2183 20.1611 41.0842 20.1611 42.2268 21.3037Z"
          fill="#01B678"
        />
        <path
          opacity="0.4"
          d="M3.25269 30.088C3.25269 15.2657 15.2657 3.25269 30.088 3.25269C44.9103 3.25269 56.9233 15.2657 56.9233 30.088C56.9233 44.9103 44.9103 56.9233 30.088 56.9233C15.2657 56.9233 3.25269 44.9103 3.25269 30.088ZM42.2268 25.4547C43.3694 24.3121 43.3694 22.4462 42.2268 21.3036C41.0842 20.161 39.2183 20.161 38.0757 21.3036L26.7336 32.6457L22.1003 28.0124C20.9577 26.8698 19.0918 26.8698 17.9492 28.0124C16.8066 29.155 16.8066 31.0209 17.9492 32.1635L24.658 38.8724C25.8006 40.015 27.6665 40.015 28.8091 38.8724L42.2268 25.4547Z"
          fill="#01B678"
        />
      </g>
      <defs>
        <clipPath id="clip0_583_19242">
          <rect width="53.6706" height="53.6706" fill="white" transform="translate(3.25269 3.25269)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CompleteCheckSvg;
