import React from 'react';

import { useFlowContext } from '../../hooks/verification-flow';

import './spinner-loader.component.scss';

interface SpinnerLoaderProps {
  className?: string;
}

const SpinnerLoader: React.FC<SpinnerLoaderProps> = ({ className }) => {
  const { state } = useFlowContext();
  const loaderType = state.sdkSettings?.loader_type;

  switch (loaderType) {
    case 'circularProgressLoader':
      return <progress className={'circular-progress-loader'} />;

    case undefined:
    default:
      return <div className={`spinner-loader ${className ?? ''}  `}></div>;
  }
};

export default SpinnerLoader;
