import { Action } from './actions';
import { ConsentTexts, PageTexts } from './modular-page-types';

export enum TemplateType {
  START = 'start',
  PAGE = 'page',
  CONSENT = 'consent',
  CAPTURING = 'capturing',
  PROCESSING = 'processing',
  ERROR_HANDLER = 'error_handler',
  VERIFICATION_IN_PROGRESS = 'verification-in-progress',
  END_VERIFICATION = 'end_verification',
  QR = 'qr',
}

interface EventData {
  eventName: string;
  actions: Action[];
}

export interface EventsBasedTemplateData {
  events: EventData[];
}

export interface ButtonData {
  text: string;
  actions: Action[];
}

// Template-specific interfaces
export interface PageTemplateData {
  texts: PageTexts;
  icon?: string;
  logo?: string;
  button?: ButtonData;
}

export interface ConsentTemplateData {
  isEnable: boolean;
  content: ConsentTexts;
  button?: ButtonData;
}

export interface EndVerificationTemplateData {
  redirect: string;
}

// Core interfaces and types
export interface TemplateContent {
  readonly type: TemplateType;
  data: PageTemplateData | ConsentTemplateData | EndVerificationTemplateData | EventsBasedTemplateData | undefined;
}
