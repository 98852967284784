import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/button/button.component';
import TenantLogoHeader from '../../components/logo-header/logo-header.component';
import PageComponent from '../../components/page/page.component';
import Text from '../../components/text/text.component';
import Title from '../../components/title/title.component';
import { mobileDetection } from '../../hooks/device-detection';
import { useFlowContext } from '../../hooks/verification-flow';
import { eventFactory } from '../../utils/monitoring/event-factory';
import { hasError } from '../../utils/monitoring/init-ui-monitoring';
import { sendEvent } from '../../utils/monitoring/send-bi';
import { objectToUrlSearchParams } from '../../utils/url-format/url-format';

import TimeoutIconSvg from './timeout.svg';

import './timeout.page.scss';

const Timeout: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useFlowContext();
  const { sid = '' } = useParams();
  const location = useLocation();
  const { state: locationState } = location;
  const { isMobile } = mobileDetection();

  const extractSessionIdFromUrl = () => {
    const url = window.location.href;
    const regex = /verify\/app\/session\/([^/]+)/;
    const match = url.match(regex);
    const id = match && match[1];
    return id;
  };
  const sessionId = sid == '' ? (state.sessionId ?? extractSessionIdFromUrl()) : sid;
  const redirectOnErrorUrl = state.sdkSettings?.redirect_on_error_url;

  hasError('true');

  useEffect(() => {
    if (sessionId) {
      const eventReport = eventFactory.createPageViewEvent('timeout');
      sendEvent(sessionId, eventReport);
    }
  }, []);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);

    const handleBackButton = () => {
      window.history.pushState(null, '', window.location.href);
    };

    const handleBackGesture = (event: PopStateEvent) => {
      event.preventDefault();
      window.history.forward();
    };
    window.addEventListener('popstate', handleBackButton);
    window.addEventListener('popstate', handleBackGesture);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
      window.removeEventListener('popstate', handleBackGesture);
    };
  }, [navigate]);

  const handleTimeoutRedirect = async (sessionId: string) => {
    const urlParameters =
      '?' +
      objectToUrlSearchParams({
        sessionId: sessionId || '',
        status: '408',
        reason: locationState.errorType,
      }).toString();

    // Ensure that redirectOnErrorUrl has a proper scheme (e.g., http:// or https://)
    let baseRedirectUrl = redirectOnErrorUrl;
    if (!baseRedirectUrl.startsWith('http://') && !baseRedirectUrl.startsWith('https://')) {
      baseRedirectUrl = 'http://' + baseRedirectUrl;
    }

    // Construct the final redirect URL with query parameters
    const redirectUrl = new URL(baseRedirectUrl);
    redirectUrl.search = urlParameters;

    const eventReport = eventFactory.createRedirectEvent(redirectUrl.toString(), 'timeout');
    sendEvent(sessionId, eventReport);

    window.location.href = redirectUrl.toString();
  };

  const { t } = useTranslation();

  const showRedirectButton = redirectOnErrorUrl && ((!state.isStartedOnDesktop && isMobile) || !isMobile);
  return (
    <PageComponent className={'timeout-component'} title={<TenantLogoHeader />}>
      <div className="timeout-component-content">
        <Title className="timeout-title" testId="session_expired">
          {t('session_expired')}
        </Title>
        <div className="timeout-icon">
          <TimeoutIconSvg />
        </div>

        {state.isStartedOnDesktop && (
          <Text className="timeout-description-redirect" testId="your_session_has_expired_go_back_to_desktop">
            <Trans t={t}>{'your_session_has_expired_go_back_to_desktop'}</Trans>
          </Text>
        )}
        {showRedirectButton && (
          <Text className="timeout-description" testId="your_session_has_expired_click_to_continue">
            <Trans t={t}>{'your_session_has_expired_click_to_continue'}</Trans>
          </Text>
        )}
        {!showRedirectButton && !state.isStartedOnDesktop && (
          <Text className="timeout-description" testId="your_session_has_expired_start_the_process_again">
            <Trans t={t}>{'your_session_has_expired_start_the_process_again'}</Trans>
          </Text>
        )}
      </div>
      {showRedirectButton && (
        <div className={'continue-button'}>
          <Button type="primary" testId="continue-button" onClick={() => handleTimeoutRedirect(state.sessionId)}>
            {t('continue')}
          </Button>
        </div>
      )}
    </PageComponent>
  );
};

export default Timeout;
