import { UAParser } from 'ua-parser-js';

import { addTag } from './monitoring/sentry';
import { sessionStorageGetValue, userTokenStorageKey } from './session-storage';
import { urlFragmentFormat } from './url-format/url-format';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
const currentUa = UAParser();

const REQUEST_HEADERS = {
  'Content-Type': 'application/json',
  'TS-platform': 'hosted-app',
  'TS-os': currentUa?.os?.name ?? '',
  'TS-os-version': currentUa?.os?.version ?? '',
  'TS-device-model': currentUa?.device?.model ?? '',
  'TS-manufacturer': currentUa?.device?.vendor ?? '',
  'TS-browser': currentUa?.browser?.name ?? '',
  'TS-sdk-version': window?.tsPlatform?.idv?.version() ?? '',
};

function getRequestHeaders() {
  const userToken = sessionStorageGetValue(userTokenStorageKey);
  if (!userToken) {
    return REQUEST_HEADERS;
  }
  return {
    Authorization: `Bearer ${userToken}`,
    ...REQUEST_HEADERS,
  };
}

async function parseHttpResponse<T>(response: Response): Promise<HttpResponse<T>> {
  const resText = await response.text();
  const resData = (!!resText && JSON.parse(resText)) || undefined;
  return { data: resData, ...response, headers: response.headers };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RequestBody = any;
export type HttpResponse<T> = { data: T } & Response;
export type ResponseError = { message: string }; // TODO: replace with proper error

export function init({ method, body, headers, ...params }: RequestInit): RequestInit {
  return {
    ...params,
    method,
    headers: {
      ...getRequestHeaders(),
      ...(headers || {}),
    },
    body,
  };
}

// implementation of HTTP fetch API. supports POST/GET and returns a promise of a result/failure.
// full structure for response/failure TBD.
function httpFetchBuilder({
  path,
  httpMethod,
  body,
  params,
  headers,
  keepalive,
}: RequestInit & {
  path: string;
  httpMethod: HttpMethod;
  body?: RequestBody;
  params?: URLSearchParams;
  headers?: HeadersInit;
}): Promise<Response> {
  const requestBody = (body && JSON.stringify(body || {})) ?? undefined;
  const reqUrl = urlFragmentFormat(path, params);
  const requestInit = init({ method: httpMethod, body: requestBody, headers, keepalive });
  // when debugging, use the following line to see the request details
  // console.log('requestInit: ', requestInit);
  addTag('requestUrl', reqUrl);
  return fetch(reqUrl, requestInit).then((response) => {
    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }
    return response;
  });
}

async function fetchRequest({
  path,
  httpMethod,
  body,
  params,
  headers,
  keepalive,
}: {
  path: string;
  httpMethod: HttpMethod;
  body?: RequestBody;
  params?: URLSearchParams;
  headers?: HeadersInit;
  keepalive?: boolean;
}): Promise<Response> {
  return httpFetchBuilder({ path, httpMethod, body, params, headers, keepalive });
}

/**
 * constructs a `GET` request
 * @param path API path
 * @param params request parameters
 * @returns a promise of the response body if successful and throw an error if failed
 */
export async function httpGet<T>(
  path: string,
  params?: URLSearchParams,
  headers?: HeadersInit,
): Promise<HttpResponse<T>> {
  return fetchRequest({ path, httpMethod: 'GET', params, headers })
    .then((httpRequest) => {
      return parseHttpResponse<T>(httpRequest);
    })
    .catch((error) => {
      console.error({ path, httpMethod: 'GET', params, headers });
      throw new Error(error);
    });
}
/**
 * constructs a `POST` request
 * @param path API path
 * @param data content of the request
 * @param keepalive whether to keep the connection alive
 * @returns a promise of the response body if successful and throw an error if failed
 */
export async function httpPost<T>(path: string, data: RequestBody, keepalive?: boolean): Promise<HttpResponse<T>> {
  return fetchRequest({ path, httpMethod: 'POST', body: data, keepalive })
    .then((httpRequest) => {
      return parseHttpResponse<T>(httpRequest);
    })
    .catch((error) => {
      console.error({ path, httpMethod: 'POST', keepalive });
      throw new Error(error);
    });
}
/**
 * constructs a `PUT` request
 * @param path API path
 * @param data content of the request
 * @param params request parameters
 * @returns a promise of the response body if successful and throw an error if failed
 */
export async function httpPut<T>(
  path: string,
  data: RequestBody,
  params?: URLSearchParams,
  headers?: HeadersInit,
): Promise<HttpResponse<T>> {
  return fetchRequest({ path, httpMethod: 'PUT', body: data, params, headers })
    .then((httpRequest) => {
      return parseHttpResponse<T>(httpRequest);
    })
    .catch((error) => {
      console.error({ path, httpMethod: 'PUT', params, headers });
      throw new Error(error);
    });
}
/**
 * constructs a `DELETE` request
 * @param path API path
 * @param body content of the request
 * @param params request parameters
 * @returns a promise of the response body if successful and throw an error if failed
 */
export async function httpDelete<T>(path: string, headers?: HeadersInit): Promise<HttpResponse<T>> {
  return fetchRequest({ path, httpMethod: 'DELETE', headers })
    .then((httpRequest) => {
      return parseHttpResponse<T>(httpRequest);
    })
    .catch((error) => {
      console.error({ path, httpMethod: 'DELETE', headers });
      throw new Error(error);
    });
}

export async function sendBeacon(path: string, data: RequestBody): Promise<boolean> {
  const requestInit = init({
    body: data,
    method: 'POST',
    keepalive: true,
  });
  requestInit.keepalive = true;

  const response = await fetch(path, requestInit);
  return response.ok;
}
