import React from 'react';

import DocumentSvg from '../components/consent-modal/document.svg';
import SelfieSvg from '../components/consent-modal/selfie.svg';
import CalendarOnboardingSvg from '../components/onboarding-instruction/calendar.svg';
import FaceOnboardingSvg from '../components/onboarding-instruction/face.svg';
import IdCardOnboardingSvg from '../components/onboarding-instruction/id-card.svg';
import ListOnboardingSvg from '../components/onboarding-instruction/list.svg';
import CameraPermissionsSvg from '../pages/camera-page/camera-permissions.svg';
import DocumentTypeRestrictedRedSvg from '../pages/modular-pages/icons/document-type-restricted-red.svg';
import ExclamationMark from '../pages/retry-page/exclamation-mark.svg';
import CompleteCheckSvg from '../pages/verification-complete/complete-check.svg';

export interface IconsBank {
  ic_red_exclamation_mark: React.FunctionComponent;
  ic_document_restricted: React.FunctionComponent;
  ic_camera_permission: React.FunctionComponent;
  ic_check_mark: React.FunctionComponent;
  ic_document_classic: React.FunctionComponent;
  ic_easily_read: React.FunctionComponent;
  ic_photocopies: React.FunctionComponent;
  ic_selfie_in_frame: React.FunctionComponent;
  ic_document_no_border: React.FunctionComponent;
  ic_selfie_document: React.FunctionComponent;
}

export const IconById: IconsBank = {
  ic_red_exclamation_mark: ExclamationMark,
  ic_document_restricted: DocumentTypeRestrictedRedSvg,
  ic_camera_permission: CameraPermissionsSvg,
  ic_check_mark: CompleteCheckSvg,
  ic_document_classic: IdCardOnboardingSvg,
  ic_easily_read: CalendarOnboardingSvg,
  ic_photocopies: ListOnboardingSvg,
  ic_selfie_in_frame: FaceOnboardingSvg,
  ic_document_no_border: DocumentSvg,
  ic_selfie_document: SelfieSvg,
};
