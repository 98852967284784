import { FlowContextValue, useFlowContext } from '../../hooks/verification-flow';
import { ConsentTemplateData, PageTemplateData, TemplateType } from '../../types/flow-step';
import ProcessingPage from '../web-sdk/sdk-processing.page';

import GeneralConsent from './general-consent-modal/general-consent.component';
import Modal from './general-modal/modal.component';
import GeneralPageComponent from './general-page/general-page.component';
import GeneralQr from './general-qr/general-qr';
import GeneralSdkFlowPage from './general-sdk-flow/general-sdk-flow.page';
import GeneralVerificationInProgress from './general-verification-in-progress/general-verification-in-progress.page';

const DisplayProvider = () => {
  const { activeStep, modalStep, closeModal, moveToStepByEvent } = useFlowContext() as Required<FlowContextValue>;

  return (
    <>
      {activeStep?.type === TemplateType.QR && <GeneralQr />}

      {activeStep?.type === TemplateType.VERIFICATION_IN_PROGRESS && <GeneralVerificationInProgress />}

      {activeStep?.type === TemplateType.PAGE && (
        <GeneralPageComponent
          pageData={activeStep.data as PageTemplateData}
          icon={(activeStep.data as PageTemplateData).icon}
          handleNextStep={moveToStepByEvent}
        />
      )}

      {activeStep?.type === TemplateType.CAPTURING && (
        <div>
          <GeneralSdkFlowPage />
        </div>
      )}

      {activeStep?.type === TemplateType.PROCESSING && <ProcessingPage />}

      {/* Always render Modal, but control visibility via state */}
      <Modal isOpen={!!modalStep} onClose={closeModal}>
        {({ visible }) => (
          <GeneralConsent
            consentData={modalStep?.data as ConsentTemplateData}
            onClose={closeModal}
            onAccepted={moveToStepByEvent}
            visible={visible}
          />
        )}
      </Modal>
    </>
  );
};

export default DisplayProvider;
