import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ConsentModal from '../../components/consent-modal/consent-modal.component';
import TenantLogoHeader from '../../components/logo-header/logo-header.component';
import OnePageOnboarding from '../../components/onboarding-instruction/one-page-onboarding.component';
import PageComponent from '../../components/page/page.component';
import { useLogger } from '../../hooks/use-logger';
import { useFlowContext } from '../../hooks/verification-flow';
import { routes } from '../../routes/routes.utils';
import { ConsentTypes } from '../../types/settings-types';
import { eventFactory } from '../../utils/monitoring/event-factory';
import { sendEvent } from '../../utils/monitoring/send-bi';

import '../onboarding-instructions/onboarding-instructions.page.scss';

const SdkOnboardingInstructions: React.FC = () => {
  const { logMessage } = useLogger('');
  const { state, dispatch } = useFlowContext();
  const { startToken = '' } = useParams();
  const navigate = useNavigate();
  const [openConsentModal, setOpenConsentModal] = useState(false);
  const isCheckboxConsent = state.sdkSettings?.consent?.type === ConsentTypes.nonUS;
  const isUsConsent = state.sdkSettings?.consent?.type === ConsentTypes.usOnly;
  const isCustomConsent = state.sdkSettings?.consent?.type === ConsentTypes.customConsent;
  const customConsentText = state.sdkSettings?.consent?.message;
  const customAdditionalConsentText = state.sdkSettings?.consent?.custom_message;
  const showConsentMessage = state.sdkSettings?.consent?.isEnable;
  useEffect(() => {
    logMessage('Showing instructions');
  }, []);
  const sdkStart = async () => {
    logMessage('Consent accepted');
    window.tsPlatform.idv.start(startToken);
  };

  const openConsent = () => {
    setOpenConsentModal(true);
    const eventReport = eventFactory.createPageViewEvent('consent');
    sendEvent(startToken, eventReport);
  };

  const handleExit = () => {
    const eventReport = eventFactory.createUserActionEvent('start_button_clicked', 'intro');
    sendEvent(startToken, eventReport);
    if (state.sessionId) {
      navigate(`/session/${state.sessionId}/${routes.sdkFlow.path}`);
      return;
    }
    dispatch({
      ...state,
      previousStep: 'ON_BOARDING',
      currentStep: 'CONSENT',
    });
    if (isCheckboxConsent || !showConsentMessage) {
      const eventReport = eventFactory.createPageViewEvent('consent');
      sendEvent(startToken, eventReport);
      sdkStart();
      return;
    }
    openConsent();
  };

  const handleAccept = () => {
    const eventReport = eventFactory.createUserActionEvent('accept_and_start_clicked', 'consent');
    sendEvent(startToken, eventReport);
    sdkStart();
  };

  useEffect(() => {
    const eventReport = eventFactory.createPageViewEvent('intro');
    sendEvent(startToken, eventReport);
  }, []);

  return (
    <>
      <PageComponent className={'onboarding-instructions'} title={<TenantLogoHeader />}>
        <>
          <OnePageOnboarding handleExit={handleExit} isCheckboxConsent={isCheckboxConsent} />
          <ConsentModal
            className={`${openConsentModal ? 'show' : ''}`}
            handleAccept={handleAccept}
            clickedExit={() => {
              logMessage('Consent declined');
              setOpenConsentModal(false);
            }}
            isUsConsent={isUsConsent}
            isCustomConsent={isCustomConsent}
            customConsentText={customConsentText}
            customAdditionalConsentText={customAdditionalConsentText}
          ></ConsentModal>
          <div
            className={`consent-modal-overlay ${openConsentModal ? 'show' : ''}`}
            onClick={() => setOpenConsentModal(!openConsentModal)}
          />
        </>
      </PageComponent>
    </>
  );
};

export default SdkOnboardingInstructions;
