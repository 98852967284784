import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../../components/button/button.component';
import Instruction from '../../../components/instruction/instruction.component';
import TenantLogoHeader from '../../../components/logo-header/logo-header.component';
import PageComponent from '../../../components/page/page.component';
import { PageTemplateData } from '../../../types/flow-step';
import { IconById } from '../../../types/icon-by-id';

import './general-page.component.scss';

const GeneralPage: React.FC<{
  pageData?: PageTemplateData;
  icon?: string;
  handleNextStep: () => void;
}> = function ({ pageData, handleNextStep, icon }) {
  const { t } = useTranslation();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleNextStep();
    }
  };
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleNextStep();
  };

  return (
    <>
      <PageComponent title={<TenantLogoHeader />} className={'white-bg'}>
        <div className={'general-page-layout'}>
          <div className={'main-section-wrapper'}>
            {icon && (
              <div className={'header-icon-svg'}>{React.createElement(IconById[icon as keyof typeof IconById])}</div>
            )}
            {pageData?.texts.title && (
              <div className={'general-instruction-title'}>
                <Trans t={t}>{pageData.texts.title}</Trans>
              </div>
            )}
            {pageData?.texts.description && (
              <p className={'general-instruction-description'}>
                <Trans t={t}>{t(pageData.texts.description)}</Trans>
              </p>
            )}
            {pageData?.texts.instructionList && pageData?.texts.instructionList.length > 0 && (
              <div className={'instructions-section'} data-auto="instructions-section">
                {pageData.texts.instructionList.map(({ text, icon }, index) => (
                  <Instruction
                    key={`${text}-${index}`}
                    Icon={IconById[icon as keyof typeof IconById]}
                    instructionTitle={t(text)}
                    testId={text}
                  ></Instruction>
                ))}
              </div>
            )}
          </div>
          {pageData?.button && (
            <div className={'action-button'} onKeyDown={handleKeyDown}>
              <Button testId="action-button" onClick={handleButtonClick}>
                {t(pageData.button.text)}
              </Button>
            </div>
          )}
        </div>
      </PageComponent>
    </>
  );
};

export default GeneralPage;
