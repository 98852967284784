import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/button/button.component';
import TenantLogoHeader from '../../components/logo-header/logo-header.component';
import PageComponent from '../../components/page/page.component';
import Text from '../../components/text/text.component';
import Title from '../../components/title/title.component';
import { mobileDetection } from '../../hooks/device-detection';
import { ErrorType } from '../../hooks/error-navigation';
import { useFlowContext } from '../../hooks/verification-flow';
import { eventFactory } from '../../utils/monitoring/event-factory';
import { hasError } from '../../utils/monitoring/init-ui-monitoring';
import { sendEvent } from '../../utils/monitoring/send-bi';
import { objectToUrlSearchParams } from '../../utils/url-format/url-format';

import ErrorIconSvg from './error.svg';

import './error.page.scss';

const Error: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useFlowContext();
  const { sid = '' } = useParams();
  const { isMobile } = mobileDetection();

  const extractSessionIdFromUrl = () => {
    const url = window.location.href;
    const regex = /verify\/app\/session\/([^/]+)/;
    const match = url.match(regex);
    const id = match && match[1];
    return id;
  };
  const sessionId = sid == '' ? (state.sessionId ?? extractSessionIdFromUrl()) : sid;
  const redirectOnErrorUrl = state.sdkSettings?.redirect_on_error_url;

  hasError('true');

  useEffect(() => {
    if (sessionId) {
      const eventReport = eventFactory.createPageViewEvent('error');
      sendEvent(sessionId, eventReport);
    }
  }, []);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);

    const handleBackButton = () => {
      window.history.pushState(null, '', window.location.href);
    };

    const handleBackGesture = (event: PopStateEvent) => {
      event.preventDefault();
      window.history.forward();
    };
    window.addEventListener('popstate', handleBackButton);
    window.addEventListener('popstate', handleBackGesture);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
      window.removeEventListener('popstate', handleBackGesture);
    };
  }, [navigate]);

  const handleErrorRedirect = async (sessionId: string) => {
    const urlParameters =
      '?' +
      objectToUrlSearchParams({ sessionId: sessionId || '', status: '500', reason: ErrorType.serverError }).toString();
    // Ensure that redirectOnErrorUrl has a proper scheme (e.g., http:// or https://)
    let baseRedirectUrl = redirectOnErrorUrl;
    if (!redirectOnErrorUrl?.startsWith('http://') && !redirectOnErrorUrl?.startsWith('https://')) {
      baseRedirectUrl = 'http://' + redirectOnErrorUrl;
    }

    // Construct the final redirect URL with query parameters
    const redirectUrl = new URL(baseRedirectUrl);
    redirectUrl.search = urlParameters;

    const eventReport = eventFactory.createRedirectEvent(redirectUrl.toString(), 'error');
    sendEvent(sessionId, eventReport);

    window.location.href = redirectUrl.toString();
  };

  const { t } = useTranslation();

  const showRedirectButton = redirectOnErrorUrl && (!isMobile || (isMobile && !state.isStartedOnDesktop));
  return (
    <PageComponent className={'error-component'} title={<TenantLogoHeader />}>
      <div className="error-component-content">
        <Title className="error-title" testId="something_went_wrong">
          {t('something_went_wrong')}
        </Title>
        <div className="error-icon">
          <ErrorIconSvg />
        </div>
        <Text className="error-description" testId="please_go_back_and_start_the_process_again">
          {t('please_go_back_and_start_the_process_again')}
        </Text>
      </div>
      {showRedirectButton && (
        <>
          <div className={'continue-button'}>
            <Button type="primary" testId="continue-button" onClick={() => handleErrorRedirect(state.sessionId)}>
              {t('continue')}
            </Button>
          </div>
        </>
      )}
    </PageComponent>
  );
};

export default Error;
