import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorType, useErrorNavigation } from '../../hooks/error-navigation';
import { useLogger } from '../../hooks/use-logger';
import { SessionStatus, useFlowContext } from '../../hooks/verification-flow';
import { StatusRes } from '../../types/verification-status-types';
import { httpGet } from '../../utils/http';
import { captureException } from '../../utils/monitoring/sentry';

const SdkFlowPage: React.FC = () => {
  let checkStatusInterval: NodeJS.Timer;
  const { handleError, handleTimeout } = useErrorNavigation();
  const { logMessage } = useLogger('');
  const { state } = useFlowContext();
  const { sid = '' } = useParams();
  useEffect(() => {
    if (sid && !state.sessionId && state.isWebSdkInitialized) {
      logMessage('page reloaded');
      window.tsPlatform.idv.start('');
    }
  }, [state.isWebSdkInitialized]);

  const parseResponse = (res: { data: StatusRes }) => {
    if (res?.data?.status) {
      state.status = res.data.status as SessionStatus;
      if (res?.data?.status === 'expired') {
        handleTimeout(
          { name: 'session expired', message: 'session expired' } as Error,
          state.currentStep,
          ErrorType.sessionTimeout,
          sid,
        );
      }
    }
  };

  const pollVerificationStatus = () => {
    let attempt = 1;

    const getStatus = () => {
      httpGet<StatusRes>(`verify/api/v1/verification/${sid}/status`)
        .then(parseResponse)
        .catch((error) => {
          if (error?.message.includes('Forbidden') || error?.message.includes('403')) {
            const additionalInfo = { sessionId: sid };
            captureException(error as Error, additionalInfo);
            handleTimeout(error as Error, state.currentStep, ErrorType.sessionTimeout, sid);
            return;
          } else {
            attempt++;
            if (attempt <= 10) {
              getStatus();
            } else {
              captureException(error as Error, { sessionId: sid, ...error });
              handleError(error as Error, state.currentStep, sid);
            }
          }
        });
    };

    getStatus();
  };

  useEffect(() => {
    checkStatusInterval = setInterval(pollVerificationStatus, 3000);
    return () => {
      clearInterval(checkStatusInterval);
    };
  }, []);

  return <></>;
};

export default SdkFlowPage;
