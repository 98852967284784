import { StatusRes } from '../types/verification-status-types';
import { httpGet } from '../utils/http';
import { eventFactory } from '../utils/monitoring/event-factory';
import { isRedirected } from '../utils/monitoring/init-ui-monitoring';
import { sendEvent } from '../utils/monitoring/send-bi';
import { objectToUrlSearchParams } from '../utils/url-format/url-format';

import { FlowContext, useFlowContext } from './verification-flow';

export enum ErrorType {
  serverError = 'serverError',
  sessionTimeout = 'sessionTimeout',
  sessionExpired = 'sessionExpired',
}

interface BuildUrlParams {
  initialRedirectUrl: string;
  status: string;
  reason: string;
  sessionId?: string;
}

export const useSessionEndNavigation = () => {
  const { state, moveToStepByError, moveToStepByEvent, dispatch } = useFlowContext();

  const handleError = (error: Error, step: string, sid?: string) => {
    if (sid) {
      const eventReport = eventFactory.createErrorEvent(error.name, error.message, step);
      sendEvent(sid, eventReport);
    }

    determineNextErrorStep('500', ErrorType.serverError, sid);
  };

  const handleTimeout = (error: Error, step: string, timeoutType: ErrorType, sid?: string, errorCode?: string) => {
    const timeoutInfo = {
      errorName: error.name,
      errorMessage: error.message,
      errorType: timeoutType,
      step,
    };

    if (sid) {
      const eventReport = eventFactory.createErrorEvent(timeoutType, error.message, step);
      sendEvent(sid, eventReport);
    }

    determineNextErrorStep(errorCode ?? '408', timeoutInfo.errorType, sid);
  };

  const handleCompletion = async (sessionId: string) => {
    const getStatus = async (sessionId: string) => {
      const res = await httpGet<StatusRes>(`verify/api/v1/verification/${sessionId}/status`);
      return { callbackUrl: res.data.callback_url, stateVal: res.data.state };
    };

    const { callbackUrl, stateVal } = await getStatus(sessionId);
    if (state.isStartedOnDesktop) {
      moveToStepByEvent('onSuccess_mobile');
    } else {
      const urlParameters =
        '?' + objectToUrlSearchParams({ sessionId: sessionId || '', state: stateVal || '' }).toString();
      const redirectUrl = callbackUrl ? callbackUrl + urlParameters : '/' + urlParameters;

      if (redirectUrl.startsWith('http://') || redirectUrl.startsWith('https://')) {
        isRedirected('true');
        const eventReport = eventFactory.createRedirectEvent(redirectUrl, 'processing');
        sendEvent(sessionId, eventReport);
        sessionStorage.setItem('redirectUrl', redirectUrl);
        sessionStorage.setItem('sessionId', sessionId);
        sessionStorage.setItem('status', 'complete');
        window.location.href = redirectUrl;
      } else {
        dispatch((prevState: FlowContext) => ({
          ...prevState,
          redirectUrl: redirectUrl,
        }));
        moveToStepByEvent('onSuccess_desktop');
      }
    }
  };

  const determineNextErrorStep = (status: string, reason: ErrorType, sessionId?: string) => {
    let errorIndex;
    if (reason === ErrorType.sessionTimeout || reason === ErrorType.sessionExpired) {
      errorIndex = 'session-expired';
    } else if (reason === ErrorType.serverError) {
      errorIndex = 'general';
    }

    if (state.isStartedOnDesktop) {
      moveToStepByError(`onError_${errorIndex}_mobile`);
    } else if (state.sdkSettings.redirect_on_error_url) {
      const redirectWithParamsUrl = buildUrlWithParams({
        initialRedirectUrl: state.sdkSettings.redirect_on_error_url,
        status,
        reason,
        sessionId,
      });

      dispatch((prevState: FlowContext) => ({
        ...prevState,
        sdkSettings: {
          ...prevState.sdkSettings,
          redirect_on_error_url: redirectWithParamsUrl,
        },
      }));

      moveToStepByError(`onError_${errorIndex}_redirect`);
    } else {
      moveToStepByError(`onError_${errorIndex}`);
    }
  };

  const buildUrlWithParams = ({ initialRedirectUrl, status, reason, sessionId }: BuildUrlParams): string => {
    const urlParameters =
      '?' +
      objectToUrlSearchParams({
        sessionId: sessionId || '',
        status,
        reason,
      }).toString();

    // Ensure that initialRedirectUrl has a proper scheme (e.g., http:// or https://)
    let baseRedirectUrl = initialRedirectUrl;
    if (!baseRedirectUrl.startsWith('http://') && !baseRedirectUrl.startsWith('https://')) {
      baseRedirectUrl = 'http://' + baseRedirectUrl;
    }

    // Construct the final redirect URL with query parameters
    const redirectWithParamsUrl = new URL(baseRedirectUrl);
    redirectWithParamsUrl.search = urlParameters;

    return redirectWithParamsUrl.toString();
  };

  return { handleError, handleTimeout, handleCompletion };
};

export default useSessionEndNavigation;
