import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingBlockComponent from '../../../components/loading-block/loading-block.component';
import TenantLogoHeader from '../../../components/logo-header/logo-header.component';
import PageComponent from '../../../components/page/page.component';
import Text from '../../../components/text/text.component';
import Title from '../../../components/title/title.component';
import useSessionEndNavigation, { ErrorType } from '../../../hooks/session-end-navigation';
import { SessionStatus, useFlowContext } from '../../../hooks/verification-flow';
import { StatusRes } from '../../../types/verification-status-types';
import { httpGet } from '../../../utils/http';
import { isRedirected } from '../../../utils/monitoring/init-ui-monitoring';
import { captureException } from '../../../utils/monitoring/sentry';
import { objectToUrlSearchParams } from '../../../utils/url-format/url-format';

import './general-verification-in-progress.page.scss';

const GeneralVerificationInProgress: React.FC = () => {
  const { handleError, handleTimeout } = useSessionEndNavigation();
  const { t } = useTranslation();
  const { state } = useFlowContext();
  let checkStatusInterval: NodeJS.Timer;
  const sid = state.sessionId || '';

  const handleVerificationResponse = (res: StatusRes) => {
    clearInterval(checkStatusInterval);
    setTimeout(() => {
      const urlParameters = '?' + objectToUrlSearchParams({ sessionId: sid, state: res.state || '' }).toString();
      const redirectUrl = res.callback_url ? res.callback_url + urlParameters : '/' + urlParameters;
      isRedirected('true');
      window.location.href = redirectUrl;
    }, 4000);
  };

  const parseResponse = (res: { data: StatusRes }) => {
    if (res?.data?.status) {
      state.status = res.data.status as SessionStatus;
      if (res.data.status === 'complete') {
        handleVerificationResponse(res.data);
      }
      if (res?.data?.status === 'expired') {
        handleTimeout(
          { name: 'session-expired', message: 'session expired' } as Error,
          state.currentStep,
          ErrorType.sessionTimeout,
          sid,
        );
      }
    } else if (
      res?.data?.state !== 'processing' &&
      res?.data?.state !== 'capturing' &&
      res?.data?.state !== 'recapture'
    ) {
      handleVerificationResponse(res?.data);
    }
  };

  const pollVerificationStatus = () => {
    let attempt = 1;

    const getStatus = () => {
      httpGet<StatusRes>(`verify/api/v1/verification/${sid}/status`)
        .then(parseResponse)
        .catch((error) => {
          if (error?.message.includes('Forbidden') || error?.message.includes('403')) {
            const additionalInfo = { sessionId: sid };
            captureException(error as Error, additionalInfo);
            handleTimeout(error as Error, state.currentStep, ErrorType.sessionTimeout, sid);
            return;
          } else {
            attempt++;
            if (attempt <= 10) {
              getStatus();
            } else {
              captureException(error as Error, { sessionId: sid, ...error });
              handleError(error as Error, state.currentStep, sid);
            }
          }
        });
    };

    getStatus();
  };

  useEffect(() => {
    checkStatusInterval = setInterval(pollVerificationStatus, 3000);
    return () => {
      clearInterval(checkStatusInterval);
    };
  }, []);
  return (
    <div className="verification-in-progress-page-wrapper">
      <PageComponent
        className={'verification-in-progress-page'}
        title={
          <div className={'page-title'}>
            <TenantLogoHeader />
          </div>
        }
      >
        <Title className="verification-in-progress">{t('verification_in_progress')}</Title>
        <Text testId="please_wait">{t('please_wait')}</Text>
        <LoadingBlockComponent />
      </PageComponent>
    </div>
  );
};

export default GeneralVerificationInProgress;
