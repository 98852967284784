import { IconPair } from './modular-page-types';

export interface BrandingContent {
  primary_color: string;
  button_color: string;
  title_font: string;
  content_font: string;
  logo_url?: string;
}

export enum ConsentTypes {
  usOnly = 'us_only',
  nonUS = 'non_us',
  customerManagedConsent = 'customer_managed_consent',
  customConsent = 'custom_consent',
}

export interface ConsentContent {
  type: ConsentTypes;
  isEnable: boolean;
  title: string;
  custom_message?: string;
  message: string;
  button: string;
  description?: string;
  icons: IconPair[];
}

export interface GeneralConsentContent {
  isEnable: boolean;
  title: string;
  description?: string;
  icons?: IconPair[];
  additionalConsentText?: string;
  consentText: string;
  button: string;
}

interface VideoCaptureConstraint {
  min: number;
  max: number;
  ideal: number;
}

interface VideoCaptureConstraints {
  width: VideoCaptureConstraint;
  height: VideoCaptureConstraint;
  frameRate: number;
}

export interface VideoCaptureSettings {
  selfieVideoConstraints?: VideoCaptureConstraints;
  environmentVideoConstraints?: VideoCaptureConstraints;
  showLoadingBlock?: boolean;
}
