export const mockUiConfig = {
  commitHash: 'dev',
  tenantId: 'mvTK5ZUwrk9KWQiwHFV0n',
  clientId: 'abcdefg',
  sessionId: 'abcdefg',
  name: 'VerifyId',
  status: 'capturing',
  missingImages: ['selfie'],
  qrScreenPollingInterval: 5000,
  settings: {
    logoUrl:
      'https://storage.googleapis.com/verifyid-dev-main-eu-static-assets/verify/app/assets/logos/mvTK5ZUwrk9KWQiwHFV0n/gGyeOFH8LUzmuwPei5JIl/3fa86609-fca2-4869-8653-d7fb6e5ac768.svg',
    theme:
      'https://storage.googleapis.com/verifyid-dev-main-eu-static-assets/verify/app/assets/themes/mvTK5ZUwrk9KWQiwHFV0n/gGyeOFH8LUzmuwPei5JIl/2b1e36e7-b2c6-408b-8095-5c1984fa979c.css',
    lng: 'en',
    loaderType: 'circularProgressLoader',
    isCameraDisabledOnInit: false,
    sdkUrl: '1.2.0-alpha.6',
    redirectOnErrorUrl: '',
  },
  sdkSettings: {},
  monitoring: {
    envType: 'dev',
    loggerDsn: 'https://228c15cbdc184d6dbb19350f53ff455a@o1200897.ingest.sentry.io/6420657',
  },
};
