import React, { useCallback, useEffect, useMemo } from 'react';

import useSessionEndNavigation, { ErrorType } from '../../../hooks/session-end-navigation';
import { useLogger } from '../../../hooks/use-logger';
import { FlowContext, useFlowContext } from '../../../hooks/verification-flow';
import { registerServiceWorker } from '../../../service-worker/service-worker-registration';
import { VerificationRecaptureReason } from '../../../types/verification-status-types';
import { addStatus } from '../../../utils/monitoring/init-ui-monitoring';
import { addTag, captureException } from '../../../utils/monitoring/sentry';

// SDK interfaces
type ErrorCodes =
  | 'other'
  | 'glare'
  | 'document_not_found'
  | 'blur'
  | 'document_not_supported'
  | 'obstructed'
  | 'document_not_matching'
  | 'face_not_found'
  | 'wrong_document_side'
  | 'glare_selfie'
  | 'blur_selfie'
  | 'multi_face'
  | 'face_rotated'
  | 'face_too_small'
  | 'face_too_close'
  | 'closed_eyes'
  | 'face_angle_too_large'
  | 'face_close_to_border'
  | 'face_occluded'
  | 'face_cropped'
  | 'barcode_not_found';
type ImageFeedback = ErrorCodes | 'ok';
type Errors = ErrorCodes | 'camera-error' | 'camera-permission-dismissed' | 'camera-permission-denied';
type Step =
  | 'document_front'
  | 'document_back'
  | 'selfie'
  | 'processing'
  | 'consent'
  | 'error'
  | 'complete'
  | 'recapture'
  | 'start';
type VerificationStatus = 'pending' | 'capturing' | 'processing' | 'recapture' | 'complete' | 'error';
type ImageType = 'document_front' | 'document_back' | 'selfie';
interface SdkEventData {
  type:
    | 'page-load'
    | 'init'
    | 'session-id-acquired'
    | 'capture-image'
    | 'capture-image-result'
    | 'start-verification-processing'
    | 'verification-result'
    | 'capture-error';
  step: Step;
  status?: VerificationStatus;
  imageType?: ImageType;
  sessionId?: string;
  feedback?: ImageFeedback;
  errorCode?: string;
  state?: string;
  callbackUrl?: string;
}
interface sessionData {
  session: string;
}
export interface CallbackMethods {
  sessionGranted?: (sessionMetadata: sessionData) => void;
  sessionStarted: (sessionMetadata: sessionData) => void;
  imageSubmitted: (sessionMetadata: sessionData, type: ImageType) => void;
  processing: (sessionMetadata: sessionData) => void;
  completed: (sessionMetadata: sessionData, callbackUrl: string) => void;
  recapture: (sessionMetadata: sessionData, reason: VerificationRecaptureReason) => void;
  error: (sessionMetadata: sessionData, error: Errors, errorCode: string) => void;
}
interface SdkInit {
  clientId: string;
  IDV: {
    serverPath: string;
    callbacks: CallbackMethods;
    callback: (event: SdkEventData) => void;
    consentVersion?: string;
    language?: string;
    startToken?: string;
    markdownSupportText?: string;
    videoCaptureSettings?: object;
    rootElement?: string;
    cdnHost?: string;
    isImageSigningEnabled?: boolean;
  };
  drs?: { serverPath: string; enableSessionToken: boolean };
}
// SDK interfaces

const GeneralIdvSdkComponent: React.FC<{ startToken: string }> = ({ startToken }) => {
  const { logMessage } = useLogger('');
  const { handleTimeout, handleError, handleCompletion } = useSessionEndNavigation();
  const { state, dispatch, moveToStepByEvent, moveToStepByError } = useFlowContext();

  const shouldSeeSdk = useMemo(() => {
    return state.sdkSettings.flow_steps['capturing'].isActive;
  }, [state]);

  const handleVerificationError = (error: Errors, errorCode: string, session: string) => {
    if (['camera-permission-denied', 'camera-permission-dismissed'].includes(error)) {
      moveToStepByError(`onError_${error}`);
    } else if (['request-error'].includes(error)) {
      if (errorCode === 'Forbidden') {
        handleTimeout(
          { name: 'session-expired', message: 'session expired' },
          state.currentStep,
          ErrorType.sessionTimeout,
          session,
        );
      } else {
        moveToStepByError('onError_general');
        handleError({ name: error, message: error }, state.currentStep, session);
      }
    } else if (['session-expired'].includes(error)) {
      handleTimeout(
        { name: 'session-expired', message: 'session expired' },
        state.currentStep,
        ErrorType.sessionExpired,
        session,
      );
    } else {
      moveToStepByError('onError_general');
    }
  };
  const handleSessionGranted = useCallback(({ session }) => {
    logMessage('session has granted');
    dispatch((prevState: FlowContext) => ({
      ...prevState,
      sessionId: session,
    }));
  }, []);
  const handleSessionStarted = useCallback(({ session }) => {
    logMessage('session has started');
    // TODO: delete this once autocapture merged to main branch in sdk
    dispatch((prevState: FlowContext) => ({
      ...prevState,
      sessionId: session,
    }));
  }, []);
  const handleImageSubmitted = useCallback(({ session }: sessionData, type: ImageType) => {
    logMessage(`image has been submitted; type: ${type}; session: ${session}`);
  }, []);
  const handleProcessing = useCallback(({ session }: sessionData) => {
    logMessage('processing has started');
    moveToStepByEvent('onProcessing');
  }, []);
  const handleCompleted = useCallback(({ session }) => {
    logMessage('verification has been completed');
    addStatus('complete');
    handleCompletion(session);
  }, []);
  const handleRecapture = useCallback(({ session }: sessionData, reason: string) => {
    dispatch((prevState: FlowContext) => ({
      ...prevState,
      recaptureReason: reason,
    }));
    logMessage('recapture has been requested');
    addStatus('recapture');
    moveToStepByEvent(`onRetry_${reason ?? 'general'}`);
  }, []);
  const handleErrorEvent = useCallback(({ session }: sessionData, error: Errors, errorCode: string) => {
    const sdkVersion = window.tsPlatform.idv.version();
    addTag('web-sdk-version', sdkVersion || 'unknown');
    addTag('web-sdk', 'true');
    captureException(new Error(error), { sessionId: session });
    handleVerificationError(error, errorCode, session);
  }, []);

  useEffect(() => {
    const sdkInit: SdkInit = {
      clientId: state.clientId,
      IDV: {
        consentVersion: state.commitHash,
        serverPath: window.location.origin + '/verify',
        language: state.sdkSettings?.lang,
        startToken: state.startToken,
        markdownSupportText: state.sdkSettings?.markdownSupportText,
        videoCaptureSettings: state.sdkSettings?.video_capture_settings,
        rootElement: 'idv-root',
        cdnHost: state.sdkSettings?.cdn_host,
        isImageSigningEnabled: state.sdkSettings.is_image_signing_enabled,
        callbacks: {
          sessionGranted: handleSessionGranted,
          sessionStarted: handleSessionStarted,
          imageSubmitted: handleImageSubmitted,
          processing: handleProcessing,
          completed: handleCompleted,
          recapture: handleRecapture,
          error: handleErrorEvent,
        },
        callback: () => {
          //ignore callback
        },
      },
    };
    if (state.sdkSettings?.drs_server_path) {
      sdkInit.drs = { serverPath: state.sdkSettings?.drs_server_path, enableSessionToken: true };
    }

    if (state.sdkSettings.autocapture_enabled) {
      registerServiceWorker(state.startToken, state.clientId, state.sdkSettings?.is_debugger_enabled ?? false);
    }

    try {
      window.tsPlatform.initialize(sdkInit);
      dispatch((prevState: FlowContext) => ({
        ...prevState,
        isWebSdkInitialized: true,
      }));
      console.log('Transmit Security SDK initialized successfully');
    } catch (e) {
      console.error(e);
      console.error('Transmit Security SDK failed to initialize');
    }
  }, []);

  return (
    <>
      <div
        id="idv-root"
        className="main-layout"
        style={{
          height: (shouldSeeSdk && '100%') || '0px',
          visibility: (shouldSeeSdk && 'visible') || 'hidden',
          display: (shouldSeeSdk && 'block') || 'none',
        }}
      ></div>
    </>
  );
};

export default GeneralIdvSdkComponent;
