import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'marked-react';

import Button from '../../../components/button/button.component';
import ExitSvg from '../../../components/consent-modal/exit.svg';
import LoadingSvg from '../../../components/consent-modal/loading.svg';
import Instruction from '../../../components/instruction/instruction.component';
import Text from '../../../components/text/text.component';
import Title from '../../../components/title/title.component';
import { useFlowContext } from '../../../hooks/verification-flow';
import { ConsentTemplateData } from '../../../types/flow-step';
import { IconById } from '../../../types/icon-by-id';

import './general-consent.component.scss';

const GeneralConsent: React.FC<{
  consentData?: ConsentTemplateData;
  onAccepted: () => void;
  visible: boolean;
  onClose?: () => void;
}> = function ({ consentData, onClose, onAccepted, visible }) {
  const { t } = useTranslation();
  const [consentAccepted, setConsentAccepted] = useState(false);
  const { state } = useFlowContext();

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    window.tsPlatform.idv.start(state.startToken);
    setConsentAccepted(true);
    event.stopPropagation();
    onAccepted();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onAccepted();
    }
  };

  return (
    <div className={`consent-modal ${visible ? 'show' : ''}`}>
      <div className={'consent-modal-head'}>
        {consentData?.content.title && <Title className={'consent-modal-title'}>{t(consentData.content.title)}</Title>}
        <Button className={'exit-button'} type={'link'} onClick={onClose}>
          <ExitSvg />
        </Button>
      </div>
      <div>
        {consentData?.content.description && (
          <Text testId="consent-modal-sub-title" className={'consent-modal-sub-title'}>
            {t(consentData.content.description)}
          </Text>
        )}
        {consentData?.content.icons && (
          <div className={'consent-icons'}>
            {consentData?.content.icons.map(({ text, icon }, index) => (
              <Instruction
                key={`${text}-${index}`}
                Icon={IconById[icon as keyof typeof IconById]}
                instructionTitle={t(text)}
                testId={text}
              ></Instruction>
            ))}
          </div>
        )}
        {consentData?.content.additionalConsentText && (
          <Text testId="consent-explanation" className={'consent-explanation'}>
            {t(consentData.content.additionalConsentText)}
          </Text>
        )}
        {consentData?.content.consentText && (
          <Text className={'consent-message'} testId="consent-message">
            <Markdown>{t(consentData?.content.consentText)}</Markdown>
          </Text>
        )}
      </div>
      {consentData?.button && (
        <div className={'continue-button'} onKeyDown={handleKeyDown}>
          <Button testId="accept-and-continue" onClick={handleButtonClick} disabled={consentAccepted}>
            {!consentAccepted ? t(consentData.button.text) : <LoadingSvg />}
          </Button>
        </div>
      )}
    </div>
  );
};

export default GeneralConsent;
