const ExclamationMark = () => {
  return (
    <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" transform="translate(0.5)" fill="white" />
      <path
        d="M30.4999 35.0173C31.8848 35.0173 33.0084 33.8938 33.0084 32.5089V19.1305C33.0084 17.7509 31.89 16.6221 30.4999 16.6221C29.1098 16.6221 27.9915 17.7509 27.9915 19.1305V32.5089C27.9915 33.899 29.1203 35.0173 30.4999 35.0173ZM30.4999 38.4769C28.6855 38.4769 27.2139 39.9485 27.2139 41.763C27.218 43.5774 28.6813 45.0511 30.4999 45.0511C32.3186 45.0511 33.786 43.5795 33.786 41.7651C33.7818 39.9506 32.3186 38.4769 30.4999 38.4769Z"
        fill="#ED3232"
      />
      <path
        opacity="0.4"
        d="M30.4999 3.24316C15.721 3.24316 3.74316 15.221 3.74316 29.9999C3.74316 44.7788 15.721 56.7567 30.4999 56.7567C45.2788 56.7567 57.2567 44.7788 57.2567 29.9999C57.2567 15.221 45.2788 3.24316 30.4999 3.24316ZM27.9915 19.13C27.9915 17.7503 29.1203 16.6215 30.4999 16.6215C31.8796 16.6215 33.0084 17.7451 33.0084 19.13V32.5084C33.0084 33.8932 31.8848 35.0168 30.4999 35.0168C29.115 35.0168 27.9915 33.8985 27.9915 32.5084V19.13ZM30.4999 45.0506C28.6855 45.0506 27.2139 43.579 27.2139 41.7645C27.2139 39.9501 28.6844 38.4785 30.4999 38.4785C32.3154 38.4785 33.786 39.9501 33.786 41.7645C33.7818 43.5769 32.3185 45.0506 30.4999 45.0506Z"
        fill="#ED3232"
      />
    </svg>
  );
};

export default ExclamationMark;
