import React, { ReactNode, useEffect, useState } from 'react';

import './modal.component.scss';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: (props: { visible: boolean }) => ReactNode; // Ensure children is a function
}

const Modal: React.FC<ModalProps> = function ({ isOpen, onClose, children }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setVisible(true), 10); // Small delay to trigger animation
    } else {
      setVisible(false);
    }
  }, [isOpen]);

  if (!isOpen && !visible) return null; // Unmount only after animation completes

  return (
    <>
      <div className={`general-modal-overlay ${visible ? 'show' : ''}`} onClick={onClose} />
      <div className={`general-modal-placement ${visible ? 'show' : ''}`}>
        <div className="general-modal-content">{children({ visible })}</div>
      </div>
    </>
  );
};

export default Modal;
