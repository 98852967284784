import { createContext, Dispatch, useContext, useReducer } from 'react';

import type { AutoCaptureSettings } from '../service-worker/vision-service/auto-capture';
import { Action, NextStepAction, RedirectAction } from '../types/actions';
import {
  ConsentTemplateData,
  EventsBasedTemplateData,
  PageTemplateData,
  TemplateContent,
  TemplateType,
} from '../types/flow-step';
import { IconsBank } from '../types/icon-by-id';
import type { BrandingContent, ConsentContent, VideoCaptureSettings } from '../types/settings-types';
import { eventFactory } from '../utils/monitoring/event-factory';
import { sendEvent } from '../utils/monitoring/send-bi';

export type RectangleCoords = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};

export type Image = {
  format: string;
  data: string;
  captureMargins: RectangleCoords | undefined;
};

export type CameraInfo = {
  label: string;
};

export interface VerificationStartResponse {
  session_id: string;
  required_images: string[];
  user_token: string;
}

export interface SdkSettings {
  is_logger_enabled: boolean;
  is_debugger_enabled: boolean;
  endpoint_ignore_list: string[];
  sdk_enabled: boolean;
  sdk_url: string;
  refine_images?: boolean;
  show_accessibility_widget?: boolean;
  show_analyzing_text?: boolean;
  strings: Record<string, string>;
  markdownSupportText?: string;
  redirect_on_error_url: string;
  consent?: ConsentContent;
  icons?: IconsBank;
  flow_steps?: Record<string, TemplateContent>;
  is_using_modular_flow?: boolean;
  branding?: BrandingContent;
  autocapture_enabled: boolean;
  autocapture_settings?: AutoCaptureSettings;
  is_image_signing_enabled?: boolean;
  video_capture_settings?: VideoCaptureSettings;
  drs_server_path: string;
  session_replay?: Date;
  lang: string;
  cdn_host?: string;
  loader_type: string;
  is_camera_disabled_on_init: boolean;
}

export type FlowActions =
  | 'DOCUMENT_FRONT'
  | 'DOCUMENT_BACK'
  | 'SELFIE'
  | 'ERROR'
  | 'PROCESSING'
  | 'ON_BOARDING'
  | 'RETRY'
  | 'CONSENT';
type ImageStatue = 'PENDING' | 'SUCCESS' | 'ERROR';
export type SessionStatus = 'pending' | 'capturing' | 'processing' | 'recapture' | 'complete' | 'error' | 'expired';
export interface VerificationAction {
  type: FlowActions;
  data?: Image;
  status?: ImageStatue;
  errorCode?: string;
  selectedCamera?: MediaDeviceInfo;
  optionalCameras?: MediaDeviceInfo[];
  startTime: number;
}

interface VerificationFlow {
  fullFlow: FlowActions[];
  actions: VerificationAction[];
  currentStep: FlowActions;
  previousStep: FlowActions;
  sessionId: string;
  startToken: string;
  requiredImages: string[];
  flowId: string;
  redirectUrl: string;
  state: string;
  tenantId: string;
  secretToken: string;
  sdkSettings: SdkSettings;
  recaptureReason?: string;
  isStartedOnDesktop: boolean;
  missingImages?: Array<'document_front' | 'document_back' | 'selfie'>;
  status?: SessionStatus;
  commitHash?: string;
  isWebSdkInitialized: boolean;
  clientId: string;
  qrScreenPollingInterval: number;
}
type FlowStepWithActive = TemplateContent & { isActive: boolean };

type ModifiedSdkSettings = Omit<SdkSettings, 'flow_steps'> & {
  flow_steps: Record<string, FlowStepWithActive>;
};

export type FlowContext = Omit<VerificationFlow, 'sdkSettings'> & {
  sdkSettings: ModifiedSdkSettings;
};

export interface FlowContextValue {
  state: FlowContext;
  dispatch: Dispatch<Partial<FlowContext> | ((prevState: FlowContext) => Partial<FlowContext>)>;
  moveToStepByEvent: (eventType?: string) => void;
  moveToStepByError: (errorName?: string) => void;
  closeModal: () => void;
  activeStep?: FlowStepWithActive;
  modalStep?: FlowStepWithActive;
}
const initialState = {} as FlowContext;

const FlowContext = createContext<FlowContextValue | undefined>(undefined);

const flowReducer = (
  state: FlowContext,
  newState: Partial<FlowContext> | ((prevState: FlowContext) => Partial<FlowContext>),
): FlowContext => {
  const resolvedState = typeof newState === 'function' ? newState(state) : newState;
  return {
    ...state,
    ...resolvedState,
  };
};

const initializeFlowSteps = (flowSteps: Record<string, TemplateContent>) => {
  return Object.fromEntries(
    Object.entries(flowSteps).map(([key, step]) => [
      key,
      {
        ...step,
        isActive: key === 'start',
      },
    ]),
  );
};

// to be able to use a global/high level context we need to define that context. it could be globally (app root)
// or fragmented, the data will be divided into smaller pieces and will be provided for relevant parts in the application
const FlowContextProvider = ({ children, value }: { children: React.ReactNode; value?: FlowContext }) => {
  const initialFlow = value
    ? {
        ...value,
        sdkSettings: {
          ...value.sdkSettings,
          ...(value.sdkSettings.is_using_modular_flow && {
            flow_steps: initializeFlowSteps(value.sdkSettings?.flow_steps),
          }),
        },
      }
    : initialState;

  const [state, dispatch] = useReducer(flowReducer, initialFlow);

  const moveToStepByEvent = (eventName?: string) => {
    dispatch((prevState) => {
      const activeStepKeys = Object.keys(prevState.sdkSettings.flow_steps).filter(
        (key) => prevState.sdkSettings.flow_steps[key].isActive,
      );

      if (!activeStepKeys?.length) return prevState; // No active step found

      // Prioritize executing actions from the modal if one is active
      const modalStepKey = activeStepKeys.find(
        (key) => prevState.sdkSettings.flow_steps[key].type === TemplateType.CONSENT,
      );

      const activeStepKey = modalStepKey || activeStepKeys[0];
      const activeStep: FlowStepWithActive = prevState.sdkSettings.flow_steps[activeStepKey];

      let actions: Action[] = [];

      if ('events' in (activeStep?.data ?? {})) {
        // Capturing steps use events
        const matchedEvent =
          (activeStep.data as EventsBasedTemplateData).events.find((e) => e.eventName === eventName) ||
          (activeStep.data as EventsBasedTemplateData).events.find(
            // fallback to general message
            (e) => e.eventName === `${eventName?.split('_')[0]}_general`,
          );
        if (matchedEvent) {
          actions = matchedEvent.actions;
        }
      } else if (activeStep.type === TemplateType.PAGE || activeStep.type === TemplateType.CONSENT) {
        // Page and Consent steps use button actions
        actions = (activeStep.data as PageTemplateData | ConsentTemplateData).button?.actions || [];
      }

      let newState = { ...prevState };
      newState = handleActions(actions, newState, activeStepKey, modalStepKey);

      return newState;
    });
  };

  const handleActions = (
    actions: Action[],
    newState: FlowContext,
    activeStepKey: string,
    modalStepKey: string | undefined,
    isErrorTransition = false,
  ) => {
    const getErrorEvent = (activeStepKey: string) => {
      if (activeStepKey.includes('expired')) {
        return 'timeout';
      } else {
        return 'error';
      }
    };

    actions.forEach(async (action) => {
      if (action.type === 'RedirectAction') {
        let redirectUrl = '';
        if ((action as RedirectAction).url) {
          redirectUrl = (action as RedirectAction).url;
        } else if (activeStepKey.includes('error')) {
          redirectUrl = newState.sdkSettings.redirect_on_error_url;
          const eventReport = eventFactory.createRedirectEvent(redirectUrl.toString(), getErrorEvent(activeStepKey));
          sendEvent(newState.sessionId, eventReport);
        } else if (activeStepKey.includes('success')) {
          redirectUrl = newState.redirectUrl;
        }

        window.location.href = redirectUrl;
      } else if (action.type === 'NextStepAction') {
        newState = handleNextStep(newState, action as NextStepAction, activeStepKey, modalStepKey, isErrorTransition);
      }
    });
    return newState;
  };

  const handleNextStep = (
    prevState: FlowContext,
    nextStepAction: NextStepAction,
    activeStepKey: string,
    modalStepKey?: string,
    isErrorTransition?: boolean,
  ): FlowContext => {
    const nextStepKey = nextStepAction.nextStepKey;
    const nextStep = prevState.sdkSettings.flow_steps[nextStepKey];

    if (!nextStep) return prevState; // Step doesn't exist, return unchanged state

    // Clone current steps to modify
    const updatedSteps = { ...prevState.sdkSettings.flow_steps };

    if (isErrorTransition) {
      // Deactivate all steps except the error step
      Object.keys(updatedSteps).forEach((key) => {
        updatedSteps[key].isActive = key === nextStepKey;
      });
    } else {
      // If transitioning from a modal, close it **only** if next step is NOT a modal
      if (modalStepKey && nextStep.type !== TemplateType.CONSENT) {
        updatedSteps[modalStepKey].isActive = false;
      }

      // If next step is a modal, keep the previous step active as well
      if (nextStep.type === TemplateType.CONSENT) {
        updatedSteps[nextStepKey].isActive = true;
        updatedSteps[activeStepKey].isActive = true; // Keep current step open
      } else {
        Object.keys(updatedSteps).forEach((key) => {
          updatedSteps[key].isActive = key === nextStepKey; // Only activate the next step
        });
      }
    }

    // Handle Capturing Step Logic // TODO
    // if (nextStep.type === TemplateType.CAPTURING) {
    //   try {
    //     if (!prevState.sessionId) {
    //       window.tsPlatform.idv.start(prevState.startToken);
    //     } else {
    //       window.tsPlatform.idv.start('');
    //     }
    //   } catch (error) {
    //     console.error('Error starting capturing:', error);
    //   }
    // }

    return { ...prevState, sdkSettings: { ...prevState.sdkSettings, flow_steps: updatedSteps } };
  };

  const moveToStepByError = (errorName?: string) => {
    dispatch((prevState) => {
      const errorHandlerStepKey = Object.keys(prevState.sdkSettings.flow_steps).find(
        (key) => prevState.sdkSettings.flow_steps[key].type === TemplateType.ERROR_HANDLER,
      );

      if (!errorHandlerStepKey) return prevState; // No error handler found

      const errorHandlerStep: FlowStepWithActive = prevState.sdkSettings.flow_steps[errorHandlerStepKey];
      const errorStep =
        (errorHandlerStep.data as EventsBasedTemplateData).events.find((error) => error.eventName === errorName) ||
        (errorHandlerStep.data as EventsBasedTemplateData).events.find(
          // fallback to general error
          (e) => e.eventName === 'onError_general',
        );

      let newState = { ...prevState };
      newState = handleActions(errorStep?.actions || [], newState, errorHandlerStepKey, undefined, true);
      return { ...newState };
    });
  };

  const closeModal = () => {
    dispatch((prevState) => {
      const activeModalStepKey = Object.keys(prevState.sdkSettings.flow_steps).find(
        (key) =>
          prevState.sdkSettings.flow_steps[key].isActive &&
          prevState.sdkSettings.flow_steps[key].type === TemplateType.CONSENT,
      );

      const updatedSteps = { ...prevState.sdkSettings.flow_steps };
      updatedSteps[activeModalStepKey as string].isActive = false;

      return { ...prevState, sdkSettings: { ...prevState.sdkSettings, flow_steps: updatedSteps } };
    });
  };

  return (
    <FlowContext.Provider value={{ state, dispatch, moveToStepByEvent, moveToStepByError, closeModal }}>
      {children}
    </FlowContext.Provider>
  );
};

const useFlowContext = () => {
  const context = useContext(FlowContext);
  if (context === undefined) {
    throw new Error('useFlowContext must be used within FlowContextProvider');
  }

  const { state, dispatch, moveToStepByEvent, moveToStepByError, closeModal } = context;

  if (state.sdkSettings?.flow_steps) {
    // Find the active full-screen step
    const activeStep = Object.values(state.sdkSettings.flow_steps).find(
      (step) => step.isActive && step.type !== TemplateType.CONSENT,
    );

    // Find the active modal (consent) step
    const modalStep = Object.values(state.sdkSettings.flow_steps).find(
      (step) => step.isActive && step.type === TemplateType.CONSENT,
    );

    return { state, dispatch, activeStep, modalStep, moveToStepByEvent, moveToStepByError, closeModal };
  }

  return { state, dispatch };
};

const addActionToContext = () => {
  const { state, dispatch } = useFlowContext();

  return {
    state,
    dispatch: (action: VerificationAction) => {
      const actionType = action.type;
      if (!state.actions) {
        state.actions = [];
      }
      const actionInStateIndex = state.actions.findIndex((a) => a.type === actionType);
      if (actionInStateIndex === -1) {
        state.actions.push(action);
      } else {
        state.actions[actionInStateIndex] = action;
      }
      dispatch(state);
    },
  };
};

export { addActionToContext, FlowContextProvider, useFlowContext };
