import React, { useEffect, useState } from 'react';

import { useFlowContext } from '../../hooks/verification-flow';

import Header from './header.component';

import './logo-header.component.scss';

interface TenantLogoHeader {
  className?: string;
}

const defaultLogoUrl = '/verify/app/assets/transmit-logo.svg';

const TenantLogoHeader: React.FC<TenantLogoHeader> = ({ className }) => {
  const { state } = useFlowContext();

  const [logoUrl, setLogoUrl] = useState<string>(() => {
    return getLogoUrl(state?.sdkSettings?.branding?.logo_url) || sessionStorage.getItem('tenantLogo') || defaultLogoUrl;
  });

  useEffect(() => {
    let logoToUse = getLogoUrl(state?.sdkSettings?.branding?.logo_url);

    if (!logoToUse) {
      logoToUse = sessionStorage.getItem('tenantLogo');
    }

    if (!logoToUse) {
      logoToUse = defaultLogoUrl;
    }

    setLogoUrl(logoToUse);
    sessionStorage.setItem('tenantLogo', logoToUse);
  }, [state?.sdkSettings?.branding?.logo_url]);

  return <Header className={className} logoUrl={logoUrl} />;
};

// TODO: remove; see https://transmitsecurity.atlassian.net/browse/VER-1442
function getLogoUrl(tenantLogoUrl: string | undefined): string | null {
  if (!tenantLogoUrl) return null;

  if (tenantLogoUrl.startsWith('/assets/logos/')) {
    return '/verify/app' + tenantLogoUrl;
  }

  return tenantLogoUrl;
}

export default TenantLogoHeader;
