const DocumentTypeRestrictedRedSvg = (): React.ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="50" viewBox="0 0 57 50" fill="none">
      <g clipPath="url(#clip0_1851_988)">
        <path
          opacity="0.4"
          d="M0.5 12.4434V40.4434C0.5 43.8753 3.29028 46.6656 6.72222 46.6656H50.2778C53.7097 46.6656 56.5 43.8753 56.5 40.4434V12.4434H0.5ZM6.72222 39.4031C6.72222 36.535 9.04583 34.2211 11.9042 34.2211H23.3083C26.1764 34.2211 28.4903 36.5448 28.4903 39.4031C28.4903 39.9767 28.0236 40.4434 27.45 40.4434H7.7625C7.18889 40.4434 6.72222 39.9767 6.72222 39.4031ZM23.8333 24.8878C23.8333 26.538 23.1778 28.1207 22.0109 29.2876C20.844 30.4545 19.2613 31.11 17.6111 31.11C15.9609 31.11 14.3782 30.4545 13.2113 29.2876C12.0444 28.1207 11.3889 26.538 11.3889 24.8878C11.3889 23.2376 12.0444 21.6549 13.2113 20.488C14.3782 19.3211 15.9609 18.6656 17.6111 18.6656C19.2613 18.6656 20.844 19.3211 22.0109 20.488C23.1778 21.6549 23.8333 23.2376 23.8333 24.8878ZM34.7222 20.2211C34.7222 19.3656 35.4222 18.6656 36.2778 18.6656H48.7222C49.5778 18.6656 50.2778 19.3656 50.2778 20.2211C50.2778 21.0767 49.5778 21.7767 48.7222 21.7767H36.2778C35.4222 21.7767 34.7222 21.0767 34.7222 20.2211ZM34.7222 26.4434C34.7222 25.5878 35.4222 24.8878 36.2778 24.8878H48.7222C49.5778 24.8878 50.2778 25.5878 50.2778 26.4434C50.2778 27.2989 49.5778 27.9989 48.7222 27.9989H36.2778C35.4222 27.9989 34.7222 27.2989 34.7222 26.4434ZM34.7222 32.6656C34.7222 31.81 35.4222 31.11 36.2778 31.11H48.7222C49.5778 31.11 50.2778 31.81 50.2778 32.6656C50.2778 33.5211 49.5778 34.2211 48.7222 34.2211H36.2778C35.4222 34.2211 34.7222 33.5211 34.7222 32.6656Z"
          fill="#ED3232"
        />
        <path
          d="M6.72222 3.11035C3.29028 3.11035 0.5 5.90063 0.5 9.33257V12.4437H56.5V9.33257C56.5 5.90063 53.7097 3.11035 50.2778 3.11035H6.72222ZM23.8333 24.8881C23.8333 23.2379 23.1778 21.6552 22.0109 20.4884C20.844 19.3215 19.2613 18.6659 17.6111 18.6659C15.9609 18.6659 14.3782 19.3215 13.2113 20.4884C12.0444 21.6552 11.3889 23.2379 11.3889 24.8881C11.3889 26.5384 12.0444 28.121 13.2113 29.2879C14.3782 30.4548 15.9609 31.1104 17.6111 31.1104C19.2613 31.1104 20.844 30.4548 22.0109 29.2879C23.1778 28.121 23.8333 26.5384 23.8333 24.8881ZM6.72222 39.4034C6.72222 39.977 7.18889 40.4437 7.7625 40.4437H27.4694C28.0431 40.4437 28.5097 39.977 28.5097 39.4034C28.5097 36.5354 26.1861 34.2215 23.3278 34.2215H11.9236C9.04583 34.2215 6.72222 36.5451 6.72222 39.4034Z"
          fill="#ED3232"
        />
      </g>
      <defs>
        <clipPath id="clip0_1851_988">
          <rect width="56" height="49.7778" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DocumentTypeRestrictedRedSvg;
