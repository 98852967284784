import { useNavigate } from 'react-router-dom';

import { routes } from '../routes/routes.utils';
import { eventFactory } from '../utils/monitoring/event-factory';
import { sendEvent } from '../utils/monitoring/send-bi';

export enum ErrorType {
  serverError = 'serverError',
  sessionTimeout = 'sessionTimeout',
}

export const useErrorNavigation = () => {
  const navigate = useNavigate();

  const handleRedirect = (url: string, state: unknown) => {
    navigate(url, { state, replace: true });
  };
  const handleError = (error: Error, step: string, sid?: string) => {
    const errorInfo = {
      errorName: error.name,
      errorMessage: error.message,
      step,
    };

    if (sid) {
      const eventReport = eventFactory.createErrorEvent(error.name, error.message, step);
      sendEvent(sid, eventReport).finally(() => {
        handleRedirect(`/verify/app/session/${sid}/${routes.error.path}`, {
          state: { ...errorInfo, errorType: ErrorType.serverError, sessionId: sid },
        });
      });
    } else {
      handleRedirect(`/verify/app/session/${sid ?? ''}/${routes.error.path}`, {
        state: { ...errorInfo, errorType: ErrorType.serverError, sessionId: sid },
      });
    }
  };

  const handleTimeout = (error: Error, step: string, timeoutType: ErrorType, sid?: string) => {
    const timeoutInfo = {
      errorName: error.name,
      errorMessage: error.message,
      step,
    };

    if (sid) {
      const eventReport = eventFactory.createErrorEvent(timeoutType, error.message, step);
      sendEvent(sid, eventReport).finally(() => {
        handleRedirect(`/session/${sid}/${routes.timeout.path}`, {
          state: { ...timeoutInfo, errorType: timeoutType },
        });
      });
    } else {
      handleRedirect(`/session/${sid ?? ''}/${routes.timeout.path}`, {
        state: { ...timeoutInfo, errorType: timeoutType },
      });
    }
  };

  return { handleError, handleTimeout };
};

export default useErrorNavigation;
